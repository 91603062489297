<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="我的钱包"
                z-index="99"
                class="header"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="div">
            <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :immediate-check="false"
                        :finished-text="finished_text"
                        @load="onLoad"
                >
                    <div class="wallet-bg">
                        <div class="wallet-list">
                            <p class="title">
                                <img src="../../assets/images/bookbean_icon.png" alt="" class="bookbean_icon">
                                书豆余额
                            </p>
                            <p class="top-right" @click="withdrawalClick">提现</p>
                        </div>
                        <div class="wallet-list">
                            <div class="title">
                                <p class="title-number">
                                    {{base_infoList.now_coin?base_infoList.now_coin:'0'}}</p>
                                <p class="title-bg">
                                    约{{base_infoList.now_coin_rmb?base_infoList.now_coin_rmb:'0.0'}}元</p>
                            </div>
                            <p class="top-right top-right-center" @click="topupClick">充值</p>
                        </div>
                        <div class="wallet-button">
                            <div class="button-left">
                                <div class="button-div">
                                    <p class="button-title">
                                        {{base_infoList.today_coin?base_infoList.today_coin:'0'}}</p>
                                    <p class="button-title">今日书豆</p>
                                </div>
                                <div class="button-div">

                                    <p class="button-title">{{base_infoList.history_coin?base_infoList.history_coin:'0'}}</p>
                                    <p class="button-title">累计书豆数</p>
                                </div>
                            </div>
                            <p class="button-right" @click="instructions">
                                <span>书豆说明</span>
                                <van-icon name="warning-o" color="#fff" size="12"/>
                            </p>
                        </div>
                    </div>
                    <p class="detail-title">书豆明细</p>
                    <div class="detail-div">

                        <template v-for="item in indexList">
                            <div>
                                <div class="detail-list">
                                    <div>
                                        <p class="detail-text">{{item.type}}</p>
                                        <p class="detail-subtitle">{{item.updated_at}}</p>
                                    </div>
                                    <p class="detail-money">{{item.value}}书豆 <img
                                            src="../../assets/images/bookbean_icon.png" alt=""
                                            class="bookbean_icon"></p>
                                </div>
                            </div>
                        </template>

                    </div>
                </van-list>
            </van-pull-refresh>
        </div>

        <!--        书豆说明-->
        <van-overlay :show="show" :lock-scroll="false">
            <div class="wrapper">
                <div class="block">
                    <p>
                        <img src="../../assets/images/book_bean_img.png" alt="" class="book_bean_img">
                    </p>
                    <p class="book-title">什么是书豆？</p>
                    <div class="block-list">
                        <template v-for="item in base_infoList.coin_introduction">
                            <div class="book-text">
                                <p><img src="../../assets/images/notice_item.png" alt="" class="notice_item"></p>
                                {{item}}
                            </div>
                        </template>
                    </div>
                </div>
                <p class="icon-delete" @click="deleteClick">
                    <van-icon name="close" color="#BFC2CC" size="30"/>
                </p>
            </div>
        </van-overlay>
    </div>
</template>

<script>
    export default {
        name: "Wallet",
        data() {
            return {
                show: false,
                page: 1,
                page_size: 10,
                base_infoList: [],
                indexList: [],
                moreList_show: false,
                refreshing: false,
                loading: false,
                finished: false,
                finished_text: ''
            }
        },
        created() {
            this.base_info()
        },
        methods: {
            base_info() {
                this.$axios.get('/api/withdraw/base_info', {
                    params: {
                        page: this.page,
                        page_size: this.page_size,
                    }
                })
                    .then(res => {
                        var res = res.data.data;
                        this.base_infoList = res
                        this.base_infoList.now_coin = this.base_infoList.now_coin.toLocaleString()
                        this.base_infoList.history_coin = this.base_infoList.history_coin.toLocaleString()
                        this.base_infoList.today_coin = this.base_infoList.today_coin.toLocaleString()
                        if (res.finance.length < this.page_size) {
                            this.finished = true
                            this.finished_text = '没有更多了'
                        } else {
                            this.finished = false
                        }
                        if (this.page == 1) {
                            this.finished_text = ''
                        }
                        this.refreshing = false
                        this.loading = false;
                        if (this.moreList_show) {
                            this.indexList = res.finance
                            this.moreList_show = false
                        } else {
                            this.indexList = this.indexList.concat(res.finance)
                        }
                    })
            },
            onRefresh() {
                //下拉刷新
                this.page = 1
                if (this.indexList.length >= this.count) {
                    this.finished = true
                } else {
                    this.finished = false
                }
                this.moreList_show = true
                this.base_info()
            },
            onLoad() {
                if (this.finished) return
                this.page++;
                this.base_info();
            },

            instructions() {
                this.show = true
            },
            deleteClick() {
                this.show = false
            },
            topupClick() {
                this.$router.replace({
                    path: '/topup'
                })
            },
            withdrawalClick() {
                this.$router.push({
                    path: '/withdrawal'
                })
            }
        }
    }
</script>

<style scoped>


    .book-text {
        font-size: 26px;
        color: #666666;
        margin-bottom: 15px;
        display: flex;
        line-height: 40px;
    }

    .notice_item {
        width: 12px;
        height: 12px;
        margin-right: 10px;
    }

    .book-title {
        text-align: center;
        color: #0A1C33;
        font-size: 36px;
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .book_bean_img {
        width: 121px;
        height: 115px;
        position: absolute;
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
    }

    .icon-delete {
        position: absolute;
        bottom: 280px;
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: relative;
    }

    .block {
        position: relative;
        width: 640px;
        background: rgba(255, 255, 255, 1);
        border-radius: 20px;
        padding: 60px 40px 40px;

    }

    .block-list {
        height: 380px;
        overflow: auto;
    }

    .detail-div {
        margin-bottom: 60px;
    }

    .detail-money {
        color: #FCAD0F;
        font-size: 28px;
        display: flex;
        align-items: center;
    }

    .detail-subtitle {
        color: #BFC2CC;
        font-size: 24px;
    }

    .detail-text {
        color: #606266;
        font-size: 28px;
        margin-bottom: 28px;
    }

    .detail-list {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 30px 0;
        border-bottom: 1px solid #F2F5F8;
    }

    .detail-title {
        font-size: 32px;
        margin-top: 60px;
        margin-bottom: 20px;
        color: #0A1C33;
    }

    .button-div {
        width: 200px;
    }

    .button-title {
        color: #fff;
        font-size: 26px;
        margin-top: 20px;
    }

    .button-right {
        display: flex;
        align-items: center;
    }

    .button-right > span {
        color: #fff;
        font-size: 24px;
        margin-right: 10px;
    }

    .button-left {
        display: flex;
        align-items: center;
    }

    .wallet-button {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 35px;
    }

    .title-bg {
        background: #fff;
        color: #4462F2;
        padding: 10px 15px;
        border-radius: 30px 30px 30px 0;
        font-size: 20px;
    }

    .title-number {
        font-weight: bold;
        margin-right: 5px;
        font-size: 42px;
    }

    .top-right-center {
        background: #fff !important;
        color: #4460F1 !important;
    }

    .top-right {
        width: 160px;
        height: 54px;
        border: 1px solid rgba(255, 255, 255, 1);
        border-radius: 27px;
        font-size: 28px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bookbean_icon {
        width: 26px;
        height: 27px;
        margin-right: 10px;
    }

    .title {
        display: flex;
        align-items: center;
        font-size: 28px;
        color: #ffffff;
    }

    .wallet-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .wallet-bg {
        padding: 40px;
        margin: 40px 0 20px 0;
        background: url("../../assets/images/wallet_bg1.png") no-repeat;
        background-size: cover;
        height: 320px;
        border-radius: 18px;
        box-shadow: 5px 19px 20px 0px #bdc2e2;
    }
</style>